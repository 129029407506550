package pages.studentDuo.ui

import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.CloseErrorModal
import entities.errorModal.ui.ErrorModal
import features.UndoAlert.ui.UndoAlert
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.DuoProfileSettings.ui.DuoProfileSettings
import pages.constructor.ui.components.modalLoader.LanguageAutoModalLoader
import pages.languageAuto.getUserProfileRequest
import pages.manageNotifications.ui.ManageNotifications
import pages.studentDuo.*
import pages.studentDuoRoadMap.ui.ProtectedStudentDuoRoadMap.ProtectedStudentDuoRoadMap
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.Provider
import react.redux.useSelector
import react.router.Route
import react.router.Routes
import react.router.useNavigate
import shared.components.ScrollToTop.ScrollToTop
import widgets.StudentDuoContent.ui.StudentStoryContent
import widgets.StudentDuoSlider.ui.ProtectedStudentDuoSlider
import widgets.StudentDuoSlider.ui.StudentDuoSliderFromCourse
import widgets.UserProfile.SetUserProfile

val StudentDuo = VFC {
    Provider {
        store = studentDuoStore
        ScrollToTop {
            StudentDuoInsideProvider {}
        }
    }
}

val StudentDuoInsideProvider = FC<Props> {
    val dispatch = useAppDispatch()
    val loader = useSelector(selectStudentDuoLoader)
    val errorModalState = useSelector(selectStudentDuoErrorModal)

    useEffectOnce {
        GlobalScope.launch {
            val userProfile = getUserProfileRequest().data ?: return@launch

            dispatch(SetUserProfile(userProfile))

            val numberOfUsedGenerations = getNumberOfUsedGenerations() ?: return@launch

            dispatch(SetStudentDuoNumberOfUsedGenerations(numberOfUsedGenerations))
        }
    }
    useDuoCourses()

    LanguageAutoModalLoader {
        loading = loader.loading
        text = loader.text
        showSuccessfulLoading = loader.showSuccessfulLoading
        subText = loader.subText
    }
    ErrorModal {
        isOpen = errorModalState.open
        title = errorModalState.title
        text = errorModalState.text
        onClose = { dispatch(CloseErrorModal()) }
    }
    section {
        css(studentStory)
        Routes {
            Route {
                path = "/folder/:folder_id"
                element = ProtectedStudentDuoRoadMap.create()
            }
            Route {
                path = "/folder/:folder_id/:request_uuid"
                element = StudentDuoSliderFromCourse.create()
            }
            Route {
                path = "/folder"
                element = ProtectedStudentDuoRoadMap.create()
            }
            Route {
                path = "/content"
                element = StudentStoryContent.create()
            }
            Route {
                path = "/exercise/:request_uuid"
                element = ProtectedStudentDuoSlider.create()
            }
            Route {
                path = "/profile-settings"
                element = DuoProfileSettings.create()
            }
            Route {
                path = "/manage-notifications"
                element = ManageNotifications.create()
            }
            Route {
                path = "*"
                element = Redirect.create()
            }
        }
        div {
            css(alert)
            UndoAlert {}
        }
    }
}

val Redirect = VFC {
    val navigate = useNavigate()

    useEffect {
        navigate("/duo/folder")
    }
}
