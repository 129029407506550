package pages.studentDuo.ui.components.UsedGenerations

import emotion.react.css
import pages.languageAuto.ui.ERoute
import pages.studentDuo.selectStudentDuoNumberOfUsedGenerations
import pages.studentDuo.selectStudentDuoUserProfile
import react.FC
import react.Props
import react.dom.html.ReactHTML.p
import react.redux.useSelector
import react.router.dom.Link
import widgets.StudentDuoContent.ui.numberOfGenerations
import widgets.StudentDuoContent.ui.paymentLink
import kotlin.js.Date

const val NUMBER_OF_FREE_ATTEMPTS = 20

val UsedGenerations = FC<Props> {
    val userProfile = useSelector(selectStudentDuoUserProfile)
    val numberOfUsedGenerations = useSelector(selectStudentDuoNumberOfUsedGenerations)

    if (userProfile == null) {
        return@FC
    }

    p {
        css(numberOfGenerations)
        val endDate = if (userProfile.endPaymentDate != null) {
            Date(userProfile.endPaymentDate * 1000)
        } else {
            null
        }

        val text = if (userProfile.tariffPlan.uppercase() != "BASIC") {
            "${userProfile.tariffPlan} tariff ${if (endDate != null) "until ${endDate.toDateString()}" else ""}"
        } else if (numberOfUsedGenerations == null || numberOfUsedGenerations == 0) {
            ""
        } else if (numberOfUsedGenerations in 1 until NUMBER_OF_FREE_ATTEMPTS) {
            "$numberOfUsedGenerations used of $NUMBER_OF_FREE_ATTEMPTS free attempts. "
        } else {
            "Free attempts expired. "
        }

        +text
        if (userProfile.tariffPlan.uppercase() == "BASIC" && numberOfUsedGenerations != 0) {
            Link {
                css(paymentLink)
                to = ERoute.PAYMENT.path
                +"Buy monthly access"
            }
        }
    }
}